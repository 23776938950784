<template>
  <div class="relative bg-white dark:bg-gray-900 pt-16 pb-32 overflow-hidden">
    <div class="relative">
      <div
        class="
          lg:mx-auto
          lg:max-w-7xl
          lg:px-8
          lg:grid
          lg:grid-cols-2
          lg:grid-flow-col-dense
          lg:gap-24
        "
      >
        <div
          class="
            px-4
            max-w-xl
            mx-auto
            sm:px-6
            lg:py-16 lg:max-w-none lg:mx-0 lg:px-0
          "
        >
          <div>
            <div>
              <router-link to="/#cinemasSection">
                <span
                  class="
                    h-12
                    w-12
                    rounded-md
                    flex
                    items-center
                    justify-center
                    bg-red-900
                    hover:bg-red-600
                    cursor-pointer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                    />
                  </svg>
                </span>
              </router-link>
            </div>
            <div class="mt-6">
              <h2
                class="
                  text-3xl
                  font-extrabold
                  tracking-tight
                  text-gray-900
                  dark:text-white
                "
              >
                {{ cinema.Cinema_name }}
              </h2>
              <p class="mt-4 text-lg text-gray-500 dark:text-gray-400">
                {{ cinema.Cinema_info }}
              </p>
              <div class="mt-6">
                <a
                  @click="openReview"
                  class="
                    inline-flex
                    px-4
                    py-2
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    shadow-sm
                    text-white
                    bg-red-900
                    hover:bg-red-600
                    cursor-pointer
                  "
                >
                  Rate this cinema
                </a>
              </div>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              <div>
                <p class="text-base text-gray-500 dark:text-gray-400">
                  Address: {{ cinema.Cinema_address }}
                </p>
              </div>
              <footer class="mt-3">
                <div class="flex items-center space-x-3">
                  <div
                    class="
                      text-base
                      font-medium
                      text-gray-700
                      dark:text-gray-500
                    "
                  >
                    Opening hours: {{ cinema.Cinema_opening_hours }}
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div
            class="
              pl-4
              -mr-48
              sm:pl-6
              md:-mr-16
              lg:px-0 lg:m-0 lg:relative lg:h-full
            "
          >
            <img
              class="
                w-full
                rounded-xl
                shadow-xl
                ring-1 ring-black ring-opacity-5
                lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none
              "
              :src="require(`../assets/cinemas/${cinema.Cinema_picture}.jpg`)"
              alt="Inbox user interface"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- stats -->
  <div v-if="ratings[1]" class="max-w-4xl mx-auto">
    <dl
      class="
        rounded-lg
        bg-white
        dark:bg-gray-800
        shadow-lg
        sm:grid sm:grid-cols-3
      "
    >
      <div
        class="
          flex flex-col
          border-b border-gray-100
          p-6
          text-center
          sm:border-0 sm:border-r
        "
      >
        <dt
          class="
            order-2
            mt-2
            text-lg
            leading-6
            font-medium
            text-gray-500
            dark:text-gray-400
          "
        >
          Comfort
        </dt>
        <dd class="order-1 text-5xl font-extrabold dark:text-gray-300">
          <span
            :class="
              ratings[3].toFixed() > 3
                ? 'text-green-600'
                : ratings[3].toFixed() == 3
                ? 'text-yellow-600'
                : 'text-red-600'
            "
            >{{ ratings[3].toFixed() }}</span
          >/5
        </dd>
      </div>
      <div
        class="
          flex flex-col
          border-t border-b border-gray-100
          p-6
          text-center
          sm:border-0 sm:border-l sm:border-r
        "
      >
        <dt
          class="
            order-2
            mt-2
            text-lg
            leading-6
            font-medium
            text-gray-500
            dark:text-gray-400
          "
        >
          Snacks
        </dt>
        <dd class="order-1 text-5xl font-extrabold dark:text-gray-300">
          <span
            :class="
              ratings[2].toFixed() > 3
                ? 'text-green-600'
                : ratings[2].toFixed() == 3
                ? 'text-yellow-600'
                : 'text-red-600'
            "
            >{{ ratings[2].toFixed() }}</span
          >/5
        </dd>
      </div>
      <div
        class="
          flex flex-col
          border-t border-gray-100
          p-6
          text-center
          sm:border-0 sm:border-l
        "
      >
        <dt
          class="
            order-2
            mt-2
            text-lg
            leading-6
            font-medium
            text-gray-500
            dark:text-gray-400
          "
        >
          Price
        </dt>
        <dd class="order-1 text-5xl font-extrabold dark:text-gray-300">
          <span
            :class="
              ratings[1].toFixed() > 3
                ? 'text-green-600'
                : ratings[1].toFixed() == 3
                ? 'text-yellow-600'
                : 'text-red-600'
            "
            >{{ ratings[1].toFixed() }}</span
          >/5
        </dd>
      </div>
    </dl>
  </div>
  <!-- reviews -->
  <div class="bg-white dark:bg-gray-900">
    <div
      class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <h2 class="text-lg font-medium text-gray-900 dark:text-gray-300">
        Top reviews
      </h2>
      <div
        class="
          mt-6
          pb-10
          border-t border-b border-gray-200
          divide-y divide-gray-200
          space-y-10
        "
      >
        <div
          v-for="rating in reviews"
          :key="rating.id"
          class="pt-10 relative lg:grid lg:grid-cols-12 lg:gap-x-8"
        >
          <!-- like button -->
          <div
            v-if="
              !rating.likes.find((user) => user.username === rating.user_name)
            "
            @click.prevent="likeFunction(rating.id)"
            class="flex ml-5 absolute right-0 top-0"
          >
            <svg
              class="
                fill
                pt-4
                cursor-pointer
                hover:text-red-900
                text-gray-400
                w-10
                transform
                -translate-y-2
              "
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 20 20"
            >
              <g fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.172 5.172a4 4 0 0 1 5.656 0L10 6.343l1.172-1.171a4 4 0 1 1 5.656 5.656L10 17.657l-6.828-6.829a4 4 0 0 1 0-5.656z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>
          <div
            v-if="rating.likes.find((u) => u.username === rating.user_name)"
            @click.prevent="likeFunction(rating.id)"
            class="flex ml-5 absolute right-0 top-0"
          >
            <svg
              class="
                fill
                pt-4
                cursor-pointer
                hover:text-red-900
                text-red-900
                w-10
                transform
                -translate-y-2
              "
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 20 20"
            >
              <g fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.172 5.172a4 4 0 0 1 5.656 0L10 6.343l1.172-1.171a4 4 0 1 1 5.656 5.656L10 17.657l-6.828-6.829a4 4 0 0 1 0-5.656z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </div>

          <div
            class="
              lg:col-start-5 lg:col-span-8
              xl:col-start-4
              xl:col-span-9
              xl:grid
              xl:grid-cols-3
              xl:gap-x-8
              xl:items-start
            "
          >
            <div>
              <div class="flex items-center xl:col-span-1">
                <div class="pl-2 z-10 relative">
                  <div class="dark:text-white top-2 left-5 absolute">
                    {{ rating.rating_snacks }}
                  </div>
                  <svg
                    class="w-9"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 313.81 334.47"
                  >
                    <title>circle_snacks</title>
                    <g
                      :class="
                        rating.rating_snacks > 3
                          ? 'text-green-600'
                          : rating.rating_snacks == 3
                          ? 'text-yellow-600'
                          : 'text-red-600'
                      "
                      class="fill-current"
                    >
                      <g>
                        <path
                          d="M256,57.23c9.85,20.05,19.93,40.11,30,60,1.14,2.11,1.14,6.75,6.87,4.43,5-1.9,3-4.43,1.83-7.39-8.93-20.9-18.09-41.59-27-62.7-3-7.6-6.88-4.22-11.23-2.75-.46-2.11,1.38-3.8,2.06-5.7,2.52-6.12,8-11,13.75-11.19,6.41-.21,8.24,7.18,10.53,12.46,9.62,22,19.24,44.12,28.63,66.29,4.81,11.61,2.52,17.74-9.16,23.65-12.14,6.33-24.74,12.24-37.1,18.36-24.51,12.25-26.57,11.83-42.15-9.07-1.14-1.69-2.52-3.38-3.89-5.07C207,122.46,194.87,106.21,183,90c-6.64-9.29-5.73-13.51,4.81-16,8-1.9,16.26-2.11,24.51-4,7.79-1.9,16.26,1.68,21.3,10.76,8.93,16.26,20.38,31.46,30.69,46.87,1.37,2.32,2.29,6.12,6.41,4.22,4.35-1.9,1.15-4.85,0-7-8.93-18.16-17.86-36.32-27-54.47C236.1,55.75,234.49,49,257.4,48.78,252.13,50.89,254.65,54.27,256,57.23Zm-5.26,80.22q-20.28-30.39-40.54-60.8c-3.44-5.28-6.42-.63-9.39.21-3.67,1.27-4.13,3.38-1.84,6.34,14.43,19.21,28.63,38.63,43.06,57.84,1.15,1.27,2.06,3.17,4.58,3C249.61,142.73,253.5,141.68,250.76,137.45Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          d="M224.66,64.83c-5.73,1.69-11.69.21-14-4.22-3.2-6.34-7.55-12-11.45-18-4.12-6.12,2.75-15,11-14.36,4.58.43,8.24.22,12.36-2.11s8.94-1.26,12.37,2.33c3.21,3.37,4.12,7.81,1.61,10.76-3.9,4.44-5.73,8.45-2.52,13.09C233.59,58.92,230.84,63.14,224.66,64.83Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          d="M235.42,21.76c-5.5-5.7-1.38-9.71,3.43-12.67,5-3.16,26.8,4.22,29.55,9.5,1.37,2.75,1.83,6.12-.46,7.81a80.12,80.12,0,0,0-16.72,16.68c-1.83,2.32-5.5,2.32-9.62,1.27C245,31.89,244.81,31.89,235.42,21.76Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          d="M173.57,43.5c1.83-6.75,14-8.65,17.41-2.74,3.89,6.76,10.07,11.82,12.82,19.42,2.52,6.76-.68,8-5.49,7.6-6-.42-10.77-.63-16.49,2.33-6.19,3.16-13.29-2.54-13.06-9.72C172,55.75,172.2,49.63,173.57,43.5Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          d="M194,32.32c-.69,1.68-2.06.84-3.67.84-9.16-.21-7.1-7.6-8.24-12C180.21,14,188,8,195.56,10.78c5.27,2.11,10.31,4.65,16.26,5.49,2.52.42,5.5,2.53,4.58,6.76C207.24,22.82,198.31,23.45,194,32.32Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          d="M219.38,0c5.73,0,8.7,4,12.6,6.76-4.13,3.38-3,9.92-6.87,12.45-3.67,2.33-3.9-4-6.88-5.27l-6.87-3.17c-1.83-.85-3.66-1.27-3.2-3.17C210,2.33,213.88-.2,219.38,0Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                      </g>
                      <path
                        id="line2"
                        d="M156.64,334.47a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.12,127.12,0,0,0,89.12,2.28,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                      ></path>
                      <path
                        id="line3"
                        d="M75.32,311.26A146.15,146.15,0,0,1,10,229.81,10,10,0,0,1,17.2,217h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,311.26Z"
                      ></path>
                      <path
                        id="line5"
                        d="M44.8,79.52h0A10,10,0,0,1,43.42,65a145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,78.33,10,10,0,0,1,44.8,79.52Z"
                      ></path>
                      <path
                        id="line4"
                        d="M14.2,204A10,10,0,0,1,2,196a147.38,147.38,0,0,1-2-24.12,145.05,145.05,0,0,1,24.35-81A10,10,0,0,1,38.8,88.52h0a10,10,0,0,1,2.26,13.36,125.25,125.25,0,0,0-21.06,70,127.28,127.28,0,0,0,1.73,20.92A10,10,0,0,1,14.2,204Z"
                      ></path>
                      <g id="line1">
                        <path
                          d="M217.58,309h0a10,10,0,0,1,4.53-12.81,126.54,126.54,0,0,0,68.17-112.36c0-1.07,0-2.14-.06-3.21v-.35l20-.55v.35c0,1.25.07,2.5.07,3.76a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,309Z"
                        ></path>
                        <path
                          d="M299,169.74h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.78a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.59l-.3.17L234,310.77l.3-.17c1.08-.61,2.16-1.23,3.24-1.88a153.16,153.16,0,0,0,32.75-26.45c27-28.79,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,169.74Z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="pl-2 z-10 relative">
                  <div class="dark:text-white top-2 left-5 absolute">
                    {{ rating.rating_comfort }}
                  </div>
                  <svg
                    class="w-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 326.38 329.15"
                  >
                    <title>circle_comfort</title>
                    <g
                      :class="
                        rating.rating_comfort > 3
                          ? 'text-green-600'
                          : rating.rating_comfort == 3
                          ? 'text-yellow-600'
                          : 'text-red-600'
                      "
                      class="fill-current"
                    >
                      <path
                        d="M220.46,77.92c2.89,14.34,11.1,14.8,20.85,13.14,22.74-3.87,45.49-7.75,68.3-11.14,15.8-2.35,18.34.5,16,16-2.66,18-2.78,18.13-20.76,20.48l-30.36,3.94c.4,6.62.76,12.66,1.15,19.2l42.54-1.9q.63,2.63,1.28,5.26c-4.29,2.18-8.39,5.67-12.9,6.34-25.93,3.82-51.94,7.13-78,10.17-4.12.48-8.51-1.32-15.16-2.48,12.64-14.48,28.36-9.18,42.3-14.44-.45-5.5-.93-11.49-1.58-19.52-8.87,1.16-16.87,2-24.78,3.28-9.64,1.59-16.65-1.42-21.58-10.1-11.2-19.7-24.1-38.57-33.58-59-6-13-7.6-28.23-10.32-42.62C162.35,6.23,167.2.39,175.39,0c7.89-.35,13.64,3.42,15.66,12.54a289.93,289.93,0,0,0,10.78,36.7c3.83,10.5,11.62,14.25,23.32,12,15.71-3.08,31.73-4.61,47.64-6.66,6.07-.78,13.29-.88,13,7.12-.11,3.17-6.62,8.07-10.81,8.81C257.37,73.61,239.51,75.45,220.46,77.92Z"
                        class="fill-current dark:text-white text-gray-900"
                      ></path>
                      <path
                        id="line2"
                        d="M156.64,329.15a145.39,145.39,0,0,1-55-10.66,10,10,0,0,1-5.27-13.73h0a10,10,0,0,1,12.67-4.86,127,127,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                      ></path>
                      <path
                        id="line3"
                        d="M75.32,305.94A146.18,146.18,0,0,1,10,224.49a10,10,0,0,1,7.16-12.81h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,305.94Z"
                      ></path>
                      <path
                        id="line5"
                        d="M44.8,74.2h0a10,10,0,0,1-1.37-14.53,145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,73,10,10,0,0,1,44.8,74.2Z"
                      ></path>
                      <path
                        id="line4"
                        d="M14.2,198.68A10,10,0,0,1,2,190.63a147.47,147.47,0,0,1-2-24.12A145.05,145.05,0,0,1,24.35,85.56,10,10,0,0,1,38.8,83.2h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,166.51a127.37,127.37,0,0,0,1.73,20.92,10,10,0,0,1-7.53,11.25Z"
                      ></path>
                      <g id="line1">
                        <path
                          d="M217.58,303.68h0a10,10,0,0,1,4.53-12.8,126.56,126.56,0,0,0,68.17-112.36c0-1.08,0-2.14-.06-3.21V175l20-.55v.34c0,1.26.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,303.68Z"
                        ></path>
                        <path
                          d="M299,164.42h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.77a132.25,132.25,0,0,1-28.3,22.85c-.91.55-1.83,1.08-2.76,1.6l-.3.17L234,305.45l.3-.17c1.08-.62,2.16-1.23,3.24-1.88A153.16,153.16,0,0,0,270.32,277c27-28.79,41.11-66.16,39.6-103.88A9.23,9.23,0,0,0,299,164.42Z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="pl-2 z-10 relative">
                  <div class="dark:text-white top-2 left-5 absolute">
                    {{ rating.rating_price }}
                  </div>

                  <svg
                    class="w-9"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 314.65 352.03"
                  >
                    <title>circle_price</title>
                    <g
                      :class="
                        rating.rating_price > 3
                          ? 'text-green-600'
                          : rating.rating_price == 3
                          ? 'text-yellow-600'
                          : 'text-red-600'
                      "
                      class="fill-current"
                    >
                      <path
                        d="M290.74,177.61,265.43,189.1c-2.27-4.34-4.54-8.67-6.7-12.75-42.23,3.08-57.43-2.83-64.26-25.95l23-10.45c9.69,4.54,17.91,8.55,26.4,12.45.67-1.2,1.46-2.16,2.14-3.36l-16.15-35.57c-24,4.33-47.78,7.68-60.22-19.74-12.56-27.66,0-47.67,22.58-63.87-2.78-6.78-5.1-12.58-7.43-18.38L210.13,0l8.07,17.79c39.54-1,54.56,5.92,59.15,26.77L254.07,55.13c-7.36-4.11-13.51-7.57-19.55-10.79-.79.95-1.71,2.27-2.5,3.22,2.24,4.94,4.88,9.41,6.61,14.58,3.84,13.15,10.55,17.85,25.81,14.5,22.63-4.61,36.13,3,44.74,19.91,9.39,18.68,6.64,37.5-7.82,52.7-4.95,5.23-10.49,9.83-15.55,14.81C287.54,169.23,289.08,173.3,290.74,177.61Zm-17-39.37c10.13-7.28,13.59-15.11,8.83-24.27-4.66-8.91-13.06-9.26-22.28-5.38ZM216.32,83.58,203.66,55.67c-8.47,6.22-12.5,12.82-8,22.09C200.08,86.78,207.22,87.72,216.32,83.58Z"
                        class="fill-current dark:text-white text-gray-900"
                      ></path>
                      <path
                        id="line2"
                        d="M156.64,352a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.19,127.19,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.44,146,146,0,0,1-48,8"
                      ></path>
                      <path
                        id="line3"
                        d="M75.32,328.83A146.18,146.18,0,0,1,10,247.38a10,10,0,0,1,7.16-12.82h0a10,10,0,0,1,11.86,6.63,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,328.83Z"
                      ></path>
                      <path
                        id="line5"
                        d="M44.8,97.08h0a10,10,0,0,1-1.37-14.54,145.42,145.42,0,0,1,98.42-48.39,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.67,125.67,0,0,0,58.31,95.9,10,10,0,0,1,44.8,97.08Z"
                      ></path>
                      <path
                        id="line4"
                        d="M14.2,221.56a10,10,0,0,1-12.21-8A147.56,147.56,0,0,1,0,189.4a145.05,145.05,0,0,1,24.35-80.95,10,10,0,0,1,14.45-2.36h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,189.4a127.25,127.25,0,0,0,1.73,20.91,10,10,0,0,1-7.53,11.25Z"
                      ></path>
                      <g id="line1">
                        <path
                          d="M217.58,326.57h0a10,10,0,0,1,4.53-12.8A126.56,126.56,0,0,0,290.28,201.4c0-1.08,0-2.14-.06-3.21v-.35l20-.56v.35c0,1.25.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,326.57Z"
                        ></path>
                        <path
                          d="M299,187.3h0a10.18,10.18,0,0,0-8.7,10.23c1.33,32.59-10.88,64.89-34.19,89.77a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.6l-.3.17L234,328.33l.3-.17c1.08-.61,2.16-1.22,3.24-1.87a153.16,153.16,0,0,0,32.75-26.45c27-28.8,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,187.3Z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>

                <!-- <div class="flex ml-5">
           <svg class="fill pt-4 dark:text-gray-400 w-10 transform -translate-y-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.172 5.172a4 4 0 0 1 5.656 0L10 6.343l1.172-1.171a4 4 0 1 1 5.656 5.656L10 17.657l-6.828-6.829a4 4 0 0 1 0-5.656z" fill="currentColor"/></g></svg>
           </div>
            -->
              </div>
            </div>
            <div class="mt-4 lg:mt-6 xl:mt-0 xl:col-span-2">
              <!-- <h3 class="text-sm font-medium text-gray-900 dark:text-gray-400">
                Title!!! here
              </h3> -->

              <div
                class="mt-3 space-y-6 text-sm text-gray-500 dark:text-white"
              />
              <p class="text-gray-500 dark:text-white">
                {{ rating.rating_comment }}
              </p>
            </div>
          </div>

          <div
            class="
              mt-6
              flex
              items-center
              text-sm
              lg:mt-0
              lg:col-start-1
              lg:col-span-4
              lg:row-start-1
              lg:flex-col
              lg:items-start
              xl:col-span-3
            "
          >
            <p class="font-medium text-gray-900 dark:text-white">
              {{ rating.name }}
            </p>
            <time
              :datetime="rating.created_at"
              class="
                ml-4
                border-l border-gray-200
                pl-4
                text-gray-500
                dark:text-gray-200
                lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0
              "
              >{{ rating.created_at }}</time
            >
            <p
              class="
                ml-4
                border-l border-gray-200
                pl-4
                text-gray-500
                dark:text-red-800
                font-bold
                text-xl
                lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0
              "
            >
              Likes: {{ rating.likes.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        auto-reopen="true"
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="open = false"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                dark:bg-gray-900
                rounded-lg
                text-left
                overflow-hidden
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
              "
            >
              <!-- Rating area start -->
              <div v-show="ratingRating" class="p-10">
                <label
                  for="about"
                  class="
                    block
                    text-sm
                    font-medium
                    dark:text-white
                    mb-5
                    text-gray-700
                  "
                >
                  Slide to rate
                  <span class="text-red-700">{{ cinema.Cinema_name }} </span>
                  from 1-5 on the 3 categories
                </label>
                <div class="flex md:ml-10">
                  <div class="pl-5 z-10">
                    <svg
                      class="w-16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 313.81 334.47"
                    >
                      <title>circle_snacks</title>
                      <g :class="snackColor" class="fill-current">
                        <g>
                          <path
                            d="M256,57.23c9.85,20.05,19.93,40.11,30,60,1.14,2.11,1.14,6.75,6.87,4.43,5-1.9,3-4.43,1.83-7.39-8.93-20.9-18.09-41.59-27-62.7-3-7.6-6.88-4.22-11.23-2.75-.46-2.11,1.38-3.8,2.06-5.7,2.52-6.12,8-11,13.75-11.19,6.41-.21,8.24,7.18,10.53,12.46,9.62,22,19.24,44.12,28.63,66.29,4.81,11.61,2.52,17.74-9.16,23.65-12.14,6.33-24.74,12.24-37.1,18.36-24.51,12.25-26.57,11.83-42.15-9.07-1.14-1.69-2.52-3.38-3.89-5.07C207,122.46,194.87,106.21,183,90c-6.64-9.29-5.73-13.51,4.81-16,8-1.9,16.26-2.11,24.51-4,7.79-1.9,16.26,1.68,21.3,10.76,8.93,16.26,20.38,31.46,30.69,46.87,1.37,2.32,2.29,6.12,6.41,4.22,4.35-1.9,1.15-4.85,0-7-8.93-18.16-17.86-36.32-27-54.47C236.1,55.75,234.49,49,257.4,48.78,252.13,50.89,254.65,54.27,256,57.23Zm-5.26,80.22q-20.28-30.39-40.54-60.8c-3.44-5.28-6.42-.63-9.39.21-3.67,1.27-4.13,3.38-1.84,6.34,14.43,19.21,28.63,38.63,43.06,57.84,1.15,1.27,2.06,3.17,4.58,3C249.61,142.73,253.5,141.68,250.76,137.45Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                          <path
                            d="M224.66,64.83c-5.73,1.69-11.69.21-14-4.22-3.2-6.34-7.55-12-11.45-18-4.12-6.12,2.75-15,11-14.36,4.58.43,8.24.22,12.36-2.11s8.94-1.26,12.37,2.33c3.21,3.37,4.12,7.81,1.61,10.76-3.9,4.44-5.73,8.45-2.52,13.09C233.59,58.92,230.84,63.14,224.66,64.83Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                          <path
                            d="M235.42,21.76c-5.5-5.7-1.38-9.71,3.43-12.67,5-3.16,26.8,4.22,29.55,9.5,1.37,2.75,1.83,6.12-.46,7.81a80.12,80.12,0,0,0-16.72,16.68c-1.83,2.32-5.5,2.32-9.62,1.27C245,31.89,244.81,31.89,235.42,21.76Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                          <path
                            d="M173.57,43.5c1.83-6.75,14-8.65,17.41-2.74,3.89,6.76,10.07,11.82,12.82,19.42,2.52,6.76-.68,8-5.49,7.6-6-.42-10.77-.63-16.49,2.33-6.19,3.16-13.29-2.54-13.06-9.72C172,55.75,172.2,49.63,173.57,43.5Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                          <path
                            d="M194,32.32c-.69,1.68-2.06.84-3.67.84-9.16-.21-7.1-7.6-8.24-12C180.21,14,188,8,195.56,10.78c5.27,2.11,10.31,4.65,16.26,5.49,2.52.42,5.5,2.53,4.58,6.76C207.24,22.82,198.31,23.45,194,32.32Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                          <path
                            d="M219.38,0c5.73,0,8.7,4,12.6,6.76-4.13,3.38-3,9.92-6.87,12.45-3.67,2.33-3.9-4-6.88-5.27l-6.87-3.17c-1.83-.85-3.66-1.27-3.2-3.17C210,2.33,213.88-.2,219.38,0Z"
                            class="fill-current dark:text-white text-gray-900"
                          ></path>
                        </g>
                        <path
                          id="line2"
                          d="M156.64,334.47a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.12,127.12,0,0,0,89.12,2.28,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                        ></path>
                        <path
                          id="line3"
                          d="M75.32,311.26A146.15,146.15,0,0,1,10,229.81,10,10,0,0,1,17.2,217h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,311.26Z"
                        ></path>
                        <path
                          id="line5"
                          d="M44.8,79.52h0A10,10,0,0,1,43.42,65a145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,78.33,10,10,0,0,1,44.8,79.52Z"
                        ></path>
                        <path
                          id="line4"
                          d="M14.2,204A10,10,0,0,1,2,196a147.38,147.38,0,0,1-2-24.12,145.05,145.05,0,0,1,24.35-81A10,10,0,0,1,38.8,88.52h0a10,10,0,0,1,2.26,13.36,125.25,125.25,0,0,0-21.06,70,127.28,127.28,0,0,0,1.73,20.92A10,10,0,0,1,14.2,204Z"
                        ></path>
                        <g id="line1">
                          <path
                            d="M217.58,309h0a10,10,0,0,1,4.53-12.81,126.54,126.54,0,0,0,68.17-112.36c0-1.07,0-2.14-.06-3.21v-.35l20-.55v.35c0,1.25.07,2.5.07,3.76a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,309Z"
                          ></path>
                          <path
                            d="M299,169.74h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.78a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.59l-.3.17L234,310.77l.3-.17c1.08-.61,2.16-1.23,3.24-1.88a153.16,153.16,0,0,0,32.75-26.45c27-28.79,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,169.74Z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <div>
                      <span class="dark:text-white pl-2 pt-2">Snacks</span>
                      <input
                        style="accent-color: red; outline: none"
                        v-model="snackRating"
                        type="range"
                        min="1"
                        max="5"
                        class="
                          z-20
                          w-16
                          bg-red-900
                          transform
                          translate-y-6
                          -translate-x-14
                        "
                      />
                      <div
                        style="transform: translate(1.4rem, -4.4rem)"
                        class="dark:text-white w-min z-20 transform text-2xl"
                      >
                        {{ snackRating }}
                      </div>
                    </div>
                  </div>
                  <div class="z-10">
                    <svg
                      class="w-16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 326.38 329.15"
                    >
                      <title>circle_comfort</title>
                      <g :class="seatColor" class="fill-current">
                        <path
                          d="M220.46,77.92c2.89,14.34,11.1,14.8,20.85,13.14,22.74-3.87,45.49-7.75,68.3-11.14,15.8-2.35,18.34.5,16,16-2.66,18-2.78,18.13-20.76,20.48l-30.36,3.94c.4,6.62.76,12.66,1.15,19.2l42.54-1.9q.63,2.63,1.28,5.26c-4.29,2.18-8.39,5.67-12.9,6.34-25.93,3.82-51.94,7.13-78,10.17-4.12.48-8.51-1.32-15.16-2.48,12.64-14.48,28.36-9.18,42.3-14.44-.45-5.5-.93-11.49-1.58-19.52-8.87,1.16-16.87,2-24.78,3.28-9.64,1.59-16.65-1.42-21.58-10.1-11.2-19.7-24.1-38.57-33.58-59-6-13-7.6-28.23-10.32-42.62C162.35,6.23,167.2.39,175.39,0c7.89-.35,13.64,3.42,15.66,12.54a289.93,289.93,0,0,0,10.78,36.7c3.83,10.5,11.62,14.25,23.32,12,15.71-3.08,31.73-4.61,47.64-6.66,6.07-.78,13.29-.88,13,7.12-.11,3.17-6.62,8.07-10.81,8.81C257.37,73.61,239.51,75.45,220.46,77.92Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          id="line2"
                          d="M156.64,329.15a145.39,145.39,0,0,1-55-10.66,10,10,0,0,1-5.27-13.73h0a10,10,0,0,1,12.67-4.86,127,127,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                        ></path>
                        <path
                          id="line3"
                          d="M75.32,305.94A146.18,146.18,0,0,1,10,224.49a10,10,0,0,1,7.16-12.81h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,305.94Z"
                        ></path>
                        <path
                          id="line5"
                          d="M44.8,74.2h0a10,10,0,0,1-1.37-14.53,145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,73,10,10,0,0,1,44.8,74.2Z"
                        ></path>
                        <path
                          id="line4"
                          d="M14.2,198.68A10,10,0,0,1,2,190.63a147.47,147.47,0,0,1-2-24.12A145.05,145.05,0,0,1,24.35,85.56,10,10,0,0,1,38.8,83.2h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,166.51a127.37,127.37,0,0,0,1.73,20.92,10,10,0,0,1-7.53,11.25Z"
                        ></path>
                        <g id="line1">
                          <path
                            d="M217.58,303.68h0a10,10,0,0,1,4.53-12.8,126.56,126.56,0,0,0,68.17-112.36c0-1.08,0-2.14-.06-3.21V175l20-.55v.34c0,1.26.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,303.68Z"
                          ></path>
                          <path
                            d="M299,164.42h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.77a132.25,132.25,0,0,1-28.3,22.85c-.91.55-1.83,1.08-2.76,1.6l-.3.17L234,305.45l.3-.17c1.08-.62,2.16-1.23,3.24-1.88A153.16,153.16,0,0,0,270.32,277c27-28.79,41.11-66.16,39.6-103.88A9.23,9.23,0,0,0,299,164.42Z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <div>
                      <span class="dark:text-white pl-2 pt-2">Comfort</span>
                      <input
                        style="accent-color: red; outline: none"
                        v-model="seatRating"
                        type="range"
                        min="1"
                        max="5"
                        class="
                          z-20
                          w-16
                          bg-red-900
                          transform
                          translate-y-6
                          -translate-x-14
                        "
                      />
                      <div
                        style="transform: translate(1.4rem, -4.4rem)"
                        class="dark:text-white w-min z-20 transform text-2xl"
                      >
                        {{ seatRating }}
                      </div>
                    </div>
                  </div>
                  <div class="z-10">
                    <svg
                      class="w-16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 314.65 352.03"
                    >
                      <title>circle_price</title>
                      <g :class="priceColor" class="fill-current">
                        <path
                          d="M290.74,177.61,265.43,189.1c-2.27-4.34-4.54-8.67-6.7-12.75-42.23,3.08-57.43-2.83-64.26-25.95l23-10.45c9.69,4.54,17.91,8.55,26.4,12.45.67-1.2,1.46-2.16,2.14-3.36l-16.15-35.57c-24,4.33-47.78,7.68-60.22-19.74-12.56-27.66,0-47.67,22.58-63.87-2.78-6.78-5.1-12.58-7.43-18.38L210.13,0l8.07,17.79c39.54-1,54.56,5.92,59.15,26.77L254.07,55.13c-7.36-4.11-13.51-7.57-19.55-10.79-.79.95-1.71,2.27-2.5,3.22,2.24,4.94,4.88,9.41,6.61,14.58,3.84,13.15,10.55,17.85,25.81,14.5,22.63-4.61,36.13,3,44.74,19.91,9.39,18.68,6.64,37.5-7.82,52.7-4.95,5.23-10.49,9.83-15.55,14.81C287.54,169.23,289.08,173.3,290.74,177.61Zm-17-39.37c10.13-7.28,13.59-15.11,8.83-24.27-4.66-8.91-13.06-9.26-22.28-5.38ZM216.32,83.58,203.66,55.67c-8.47,6.22-12.5,12.82-8,22.09C200.08,86.78,207.22,87.72,216.32,83.58Z"
                          class="fill-current dark:text-white text-gray-900"
                        ></path>
                        <path
                          id="line2"
                          d="M156.64,352a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.19,127.19,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.44,146,146,0,0,1-48,8"
                        ></path>
                        <path
                          id="line3"
                          d="M75.32,328.83A146.18,146.18,0,0,1,10,247.38a10,10,0,0,1,7.16-12.82h0a10,10,0,0,1,11.86,6.63,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,328.83Z"
                        ></path>
                        <path
                          id="line5"
                          d="M44.8,97.08h0a10,10,0,0,1-1.37-14.54,145.42,145.42,0,0,1,98.42-48.39,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.67,125.67,0,0,0,58.31,95.9,10,10,0,0,1,44.8,97.08Z"
                        ></path>
                        <path
                          id="line4"
                          d="M14.2,221.56a10,10,0,0,1-12.21-8A147.56,147.56,0,0,1,0,189.4a145.05,145.05,0,0,1,24.35-80.95,10,10,0,0,1,14.45-2.36h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,189.4a127.25,127.25,0,0,0,1.73,20.91,10,10,0,0,1-7.53,11.25Z"
                        ></path>
                        <g id="line1">
                          <path
                            d="M217.58,326.57h0a10,10,0,0,1,4.53-12.8A126.56,126.56,0,0,0,290.28,201.4c0-1.08,0-2.14-.06-3.21v-.35l20-.56v.35c0,1.25.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,326.57Z"
                          ></path>
                          <path
                            d="M299,187.3h0a10.18,10.18,0,0,0-8.7,10.23c1.33,32.59-10.88,64.89-34.19,89.77a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.6l-.3.17L234,328.33l.3-.17c1.08-.61,2.16-1.22,3.24-1.87a153.16,153.16,0,0,0,32.75-26.45c27-28.8,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,187.3Z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <div>
                      <span class="dark:text-white pl-2 pt-2">Price</span>
                      <input
                        style="accent-color: red"
                        v-model="priceRating"
                        type="range"
                        min="1"
                        max="5"
                        class="
                          z-20
                          w-16
                          bg-red-900
                          transform
                          translate-y-6
                          -translate-x-14
                        "
                      />
                      <div
                        style="transform: translate(1.4rem, -4.4rem)"
                        class="dark:text-white w-min z-20 text-2xl"
                      >
                        {{ priceRating }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Review area start -->
              <div v-show="ratingReview" class="p-10">
                <label
                  for="about"
                  class="
                    block
                    text-sm
                    font-medium
                    dark:text-white
                    text-gray-700
                  "
                >
                  Review
                </label>
                <div class="mt-1">
                  <textarea
                    v-model="reviewText"
                    id="about"
                    name="about"
                    rows="3"
                    class="
                      shadow-sm
                      dark:bg-gray-800
                      mt-1
                      block
                      w-full
                      sm:text-sm
                      border border-gray-300
                      dark:border-gray-800 dark:text-white
                      rounded-md
                    "
                    placeholder="Write Review"
                  />
                </div>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                  This part is optional (submit without writing a review to
                  skip)
                </p>
                <div
                  v-if="error"
                  class="rounded-md mt-2 dark:bg-red-600 bg-red-50 p-4"
                >
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <XCircleIcon
                        class="h-5 w-5 dark:text-white text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3">
                      <h3
                        class="text-sm font-medium dark:text-white text-red-800"
                      >
                        {{ error }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <!-- navigation area -->
              <div
                class="
                  bg-gray-50
                  dark:bg-gray-900
                  px-4
                  py-3
                  sm:px-6 sm:flex sm:flex-row-reverse
                "
              >
                <button
                  v-show="ratingRating"
                  @click="Fieldchange('next')"
                  type="button"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    shadow-sm
                    px-4
                    py-2
                    bg-red-900
                    text-base
                    font-medium
                    text-white
                    hover:bg-red-600
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    sm:ml-3 sm:w-auto sm:text-sm
                  "
                >
                  Next
                </button>
                <button
                  v-show="ratingReview"
                  @click="submitForm"
                  type="button"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    shadow-sm
                    px-4
                    py-2
                    bg-red-900
                    text-base
                    font-medium
                    text-white
                    hover:bg-red-600
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    sm:ml-3 sm:w-auto sm:text-sm
                  "
                >
                  Submit
                </button>
                <button
                  v-show="ratingReview"
                  @click="Fieldchange('back')"
                  type="button"
                  class="
                    mt-3
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    border
                    shadow-sm
                    px-4
                    py-2
                    bg-white
                    text-base
                    font-medium
                    text-gray-700
                    hover:bg-gray-50
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                  "
                >
                  Back
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
// import { StarIcon } from '@heroicons/vue/solid'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "cinema",
  data() {
    return {
      cinema: "",
      open: false,
      ratingReview: false,
      ratingRating: true,
      reviewText: "",
      snackRating: 1,
      seatRating: 1,
      priceRating: 1,
      snackColor: "text-red-700",
      seatColor: "text-red-700",
      priceColor: "text-red-700",
      ratings: "",
      reviews: "",
      error: null,
    };
  },
  computed: {
    ...mapGetters({ user: "user" }),
  },
  watch: {
    // whenever question changes, this function will run
    snackRating: function () {
      if (this.snackRating < 3) {
        console.log("red");
        this.snackColor = "text-red-700";
      } else if (this.snackRating == 3) {
        console.log("yellow");
        this.snackColor = "text-yellow-500";
      } else if (this.snackRating > 3) {
        console.log("green");
        this.snackColor = "text-green-600";
      }
    },
    seatRating: function () {
      if (this.seatRating < 3) {
        console.log("red");
        this.seatColor = "text-red-700";
      } else if (this.seatRating == 3) {
        console.log("yellow");
        this.seatColor = "text-yellow-500";
      } else if (this.seatRating > 3) {
        console.log("green");
        this.seatColor = "text-green-600";
      }
    },
    priceRating: function () {
      if (this.priceRating < 3) {
        console.log("red");
        this.priceColor = "text-red-700";
      } else if (this.priceRating == 3) {
        console.log("yellow");
        this.priceColor = "text-yellow-500";
      } else if (this.priceRating > 3) {
        console.log("green");
        this.priceColor = "text-green-600";
      }
    },
  },
  components: {
    // StarIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    async likeFunction(rating_id) {
      const response = await axios.post(
        "like",
        {
          user_id: this.user.id,
          rating_id: rating_id,
          like_status: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data);

      //gentagelse fra created
      let route2 = "SingleCinemaRatings/" + this.$route.params.id;
      const ratings = await axios.get(route2, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.ratings = ratings.data;
      let reviews = [];
      ratings.data[0].forEach((rating) => {
        if (rating.rating_comment != null) {
          reviews.push(rating);
        }
      });
      this.reviews = reviews;
    },
    openReview() {
      this.open = true;
    },
    Fieldchange(type) {
      if (type === "next") {
        this.ratingRating = false;
        this.ratingReview = true;
      }
      if (type === "back") {
        this.ratingRating = true;
        this.ratingReview = false;
      }
    },
    async submitForm() {
      try {
        const response = await axios.post(
          "rating",
          {
            user_id: this.user.id,
            cinema_id: this.$route.params.id,
            rating_comfort: this.seatRating,
            rating_price: this.priceRating,
            rating_snacks: this.snackRating,
            rating_comment: this.reviewText,
            rating_status: 1,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        console.log(response.data);
        this.open = false;
        //gentagelse fra created
        let route2 = "SingleCinemaRatings/" + this.$route.params.id;
        const ratings = await axios.get(route2, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.ratings = ratings.data;
        let reviews = [];
        ratings.data[0].forEach((rating) => {
          if (rating.rating_comment != null) {
            reviews.push(rating);
          }
        });
        this.reviews = reviews;
      } catch (e) {
        this.error = "you have already rated this cinema";
        console.log("you have already reviewed this cinema");
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    let route = "cinema/" + this.$route.params.id;
    let route2 = "SingleCinemaRatings/" + this.$route.params.id;
    console.log(this.$route.params.id);
    const cinema = await axios.get(route, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const ratings = await axios.get(route2, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log(cinema.data);
    this.cinema = cinema.data;
    console.log(ratings.data);
    this.ratings = ratings.data;
    let reviews = [];
    ratings.data[0].forEach((rating) => {
      if (rating.rating_comment != null) {
        reviews.push(rating);
      }
    });
    this.reviews = reviews;
    console.log(reviews);
    const user = await axios.get("user", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    this.$store.dispatch("user", user.data);
  },
};
</script>

<style></style>
