<template>
  <div class="home">
    <Homecomponent />
  </div>
</template>

<script>
// @ is an alias to /src
import Homecomponent from "@/components/Homecomponent.vue";

export default {
  name: "Home",
  components: {
    Homecomponent,
  },
};
</script>
