import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Cinema from "../views/Cinema.vue";
import Reset from "../views/Reset.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/reset/:token",
    name: "reset",
    component: Reset,
  },
  {
    path: "/cinema/:id",
    name: "Cinema",
    component: Cinema,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
