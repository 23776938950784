<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="$store.state.open">
    <Dialog
      as="div"
      auto-reopen="true"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$store.state.open = false"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              fixed
              inset-0
              bg-gray-500
              dark:bg-gray-800 dark:bg-opacity-75
              bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              dark:bg-gray-800
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div class="mt-3 text-center sm:mt-5">
              <DialogTitle
                as="h3"
                class="
                  text-lg
                  leading-6
                  font-medium
                  text-gray-900
                  dark:text-white
                "
              >
                Sign up for CinemaRunner
              </DialogTitle>
            </div>
            <div class="mt-10 sm:mt-0">
              <div class="md:grid md:grid-cols-2 md:gap-6">
                <div class="mt-5 md:mt-0 md:col-span-2">
                  <form @submit.prevent="Signup">
                    <div class="overflow-hidden sm:rounded-md">
                      <div class="px-4 py-5 bg-white dark:bg-gray-800 sm:p-6">
                        <div class="grid grid-cols-6 gap-6">
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="first-name"
                              class="
                                block
                                text-sm
                                font-medium
                                dark:text-white
                                text-gray-700
                              "
                              >Username</label
                            >
                            <input
                              v-model="$store.state.signupName"
                              type="text"
                              name="first-name"
                              id="first-name"
                              autocomplete="given-name"
                              required=""
                              class="
                                mt-1
                                dark:bg-gray-800 dark:text-white
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                rounded-md
                              "
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="last-name"
                              class="
                                block
                                text-sm
                                font-medium
                                dark:text-white
                                text-gray-700
                              "
                              >Password</label
                            >
                            <input
                              v-model="$store.state.signupPassword"
                              type="password"
                              name="last-name"
                              id="last-name"
                              autocomplete="family-name"
                              required=""
                              class="
                                mt-1
                                dark:text-white dark:bg-gray-800
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                rounded-md
                              "
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-4">
                            <label
                              for="email-address"
                              class="
                                block
                                text-sm
                                font-medium
                                dark:text-white
                                text-gray-700
                              "
                              >Email address</label
                            >
                            <input
                              v-model="$store.state.signupEmail"
                              type="email"
                              name="email-address"
                              id="email-address"
                              autocomplete="email"
                              required=""
                              class="
                                mt-1
                                dark:text-white dark:bg-gray-800
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="$store.state.signupError"
                        class="rounded-md dark:bg-red-600 bg-red-50 p-4"
                      >
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <XCircleIcon
                              class="h-5 w-5 dark:text-white text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div class="ml-3">
                            <h3
                              class="
                                text-sm
                                font-medium
                                dark:text-white
                                text-red-800
                              "
                            >
                              {{ $store.state.signupError }}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          px-4
                          py-3
                          bg-gray-50
                          dark:bg-gray-800
                          text-right
                          sm:px-6
                        "
                      >
                        <button
                          type="submit"
                          class="
                            inline-flex
                            justify-center
                            py-2
                            px-4
                            border border-transparent
                            shadow-sm
                            text-sm
                            font-medium
                            rounded-md
                            text-white
                            bg-red-700
                            hover:bg-red-500
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:red-indigo-700
                          "
                        >
                          Create Account
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XCircleIcon } from "@heroicons/vue/solid";
export default {
  name: "signup",
  components: {
    XCircleIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    Signup() {
      this.$store.dispatch("signup");
    },
  },
};
</script>
