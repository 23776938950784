<template>
  <div>
    <!-- New stuff -->
    <div class="relative bg-white dark:bg-gray-900 overflow-hidden">
      <div class="">
        <div
          class="
            relative
            z-10
            pb-8
            dark:bg-gray-900
            bg-white
            sm:pb-16
            md:pb-20
            lg:max-w-2xl lg:w-full lg:pb-28
            xl:pb-32
          "
        >
          <svg
            class="
              hidden
              lg:block
              absolute
              right-0
              inset-y-0
              h-full
              w-48
              text-white
              dark:text-gray-900
              transform
              translate-x-1/2
            "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                class="
                  relative
                  flex
                  items-center
                  justify-between
                  sm:h-10
                  lg:justify-start
                "
                aria-label="Global"
              >
                <div
                  class="
                    flex
                    items-center
                    flex-grow flex-shrink-0
                    lg:flex-grow-0
                  "
                >
                  <div
                    class="flex items-center justify-between w-full md:w-auto"
                  >
                    <a href="#">
                      <span class="sr-only">Workflow</span>
                      <svg
                        class="mx-auto h-14 w-auto"
                        id="price"
                        xmlns="http://www.w3.org/2000/svg"
                        width="116.26mm"
                        height="115.5mm"
                        viewBox="0 0 329.54 327.39"
                      >
                        <g>
                          <path
                            d="M164.77,327.39a157.28,157.28,0,0,1-78.21-20.65,15.09,15.09,0,0,1-4.81-21.91h0a15.12,15.12,0,0,1,19.78-4.27,128.22,128.22,0,0,0,126.48,0,15.12,15.12,0,0,1,19.78,4.27h0A15.09,15.09,0,0,1,243,306.74,157.25,157.25,0,0,1,164.77,327.39Z"
                            class="fill-current text-red-800"
                          />
                          <path
                            d="M255.65,279.72h0a15.05,15.05,0,0,1,1.93-20.05A128.36,128.36,0,0,0,296.8,139.28,15.14,15.14,0,0,1,307,121.79h0a15.06,15.06,0,0,1,19.32,11.26A158.53,158.53,0,0,1,278,281.86,15.09,15.09,0,0,1,255.65,279.72Z"
                            class="fill-current text-red-800"
                          />
                          <path
                            d="M25.9,112.58h0a15.1,15.1,0,0,1-9-20.6A158.36,158.36,0,0,1,143.43.1,15.1,15.1,0,0,1,160.25,15h0A15.09,15.09,0,0,1,146.8,30.07,128.24,128.24,0,0,0,44.4,104.45,15.09,15.09,0,0,1,25.9,112.58Z"
                            class="fill-current text-red-800"
                          />
                          <path
                            d="M73.9,279a15.11,15.11,0,0,1-22.37,2.13,158.51,158.51,0,0,1-48.3-148.8,15.05,15.05,0,0,1,19.32-11.26h0A15.12,15.12,0,0,1,32.74,138.6,128.34,128.34,0,0,0,72,259,15.08,15.08,0,0,1,73.9,279Z"
                            class="fill-current text-red-800"
                          />
                          <path
                            d="M285.14,104.45a128.22,128.22,0,0,0-102.4-74.38A15.1,15.1,0,0,1,169.29,15h0A15.1,15.1,0,0,1,186.11.1,158.35,158.35,0,0,1,312.6,92a15.1,15.1,0,0,1-9,20.6h0A15.1,15.1,0,0,1,285.14,104.45Z"
                            class="fill-current text-red-800"
                          />
                          <g>
                            <path
                              d="M233.3,192.6l-15.14-9.66c-2.38,6.09-1,12.06-1.21,17.82-.46,10.87-5.39,16.08-16.37,16.12-31.53.1-63.06.05-94.59,0-7.25,0-13.07-2.17-14.7-10.27.24-31,.36-62.06-.07-93.09.89-7.57,6.3-10.2,12.72-10.25q49.45-.35,98.92,0c8.86.08,13.45,5.44,14,14.61.18,3,.05,6,.05,9.08s0,5.89,0,9.3c6.52-1.14,9.91-6,14.8-8.06a2,2,0,0,1,2.84.59,10.64,10.64,0,0,1,2.64,5.68c1.25,17.13.94,34.28.2,51.43A7.94,7.94,0,0,1,233.3,192.6Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M91.22,113.55c2.42,1.66,2,4.2,2,6.58q0,39.94,0,79.9c0,2.38.41,4.92-2,6.61-2.09-3.35-1.21-7.08-1.22-10.65q-.12-36.35,0-72.7C90.08,120,89.21,116.6,91.22,113.55Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M244.25,195.85l-.82,0-.79-.24c-2.21-2.41-2.49-5.39-2.51-8.44q-.13-27.09,0-54.18c0-3,.22-6,2.37-8.49,4.52-3.69,6.07-.86,6.09,3.19q.22,32.25,0,64.49c0,2.34.32,6.22-4.3,3.7,2.33-7.66.9-15.51,1-23.25.25-14.51.07-29,.07-43.53C244.57,151.36,246.82,173.64,244.25,195.85Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M233.51,128.53l-1.81-.31c1.21-1.23,2.42-2.47,3.64-3.7,3.93-1.68,5.17,1.07,5.18,3.86.12,21.91,1.25,43.84-.57,65.73,0,.53-.64,1-1,1.5-1.64.35-3,.06-3.94-1.51,2.67-19.68.64-39.47,1.26-59.2A8.24,8.24,0,0,0,233.51,128.53Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M233.51,128.53c1.69-.83,3.65-1.79,3.64,1.34q-.12,31.09-.49,62.2c0,.68-1.06,1.36-1.63,2l-1.73-1.51C236.81,171.25,236.32,149.89,233.51,128.53Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M239,195.61c0-21.13.09-42.25,0-63.38,0-2.9,1.73-7.09-3.6-7.71,1.85-2.41,3.69-2.26,5.53,0a592,592,0,0,1,0,71.11Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M240.83,195.63q0-35.55,0-71.1h1.64q.06,35.55.13,71.11h-.91Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M134.57,161.28c0-6.07.07-12.14,0-18.21-.07-4,1-5.95,5.25-3.67q18,9.65,36.16,19.19c4.27,2.24,2.57,4-.35,5.56-12,6.46-24.11,12.8-36.12,19.29-3.67,2-4.94.73-4.91-3.05C134.61,174,134.58,167.65,134.57,161.28Z"
                              class="fill-current text-white"
                            />
                            <path
                              d="M100.87,212.45H206.06v.9h-105Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M104.81,104.83H202v.89H104.44Z"
                              class="fill-current text-red-800"
                            />
                            <path
                              d="M244.25,195.85V126.22H246q0,33.81-.08,67.63c0,.68-1,1.37-1.61,2.06Z"
                              class="fill-current text-red-800"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="md:ml-10 md:pr-4 md:space-x-8">
                  <a
                    class="font-medium text-red-900 hover:text-red-700"
                    @click="logout"
                    href="javascript:void(0)"
                    >Log out</a
                  >
                </div>
                <div @click="switchMode" class="dark:text-white cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </nav>
            </div>

            <transition
              enter-active-class="duration-150 ease-out"
              enter-from-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="duration-100 ease-in"
              leave-from-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
            </transition>
          </Popover>

          <main
            class="
              mt-10
              dark:bg-gray-900
              mx-auto
              max-w-7xl
              px-4
              sm:mt-12 sm:px-6
              md:mt-16
              lg:mt-20 lg:px-8
              xl:mt-28
            "
          >
            <div class="sm:text-center lg:text-left">
              <h1
                class="
                  text-4xl
                  tracking-tight
                  font-extrabold
                  dark:text-white
                  text-gray-900
                  sm:text-5xl
                  md:text-6xl
                "
              >
                <span class="block xl:inline">Welcome to</span>
                {{ " " }}
                <span class="block text-red-900 xl:inline">CinemaRunner</span>
              </h1>
              <p
                class="
                  mt-3
                  text-base text-gray-500
                  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                  md:mt-5 md:text-xl
                  lg:mx-0
                "
              >
                This site is made with the main intend of making your choice of
                cinema easier for you. Through our unique online rating system
                you can get a good idea of what kind of cinema to look for in a
                specified city!
              </p>
              <div
                class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
              >
                <div class="rounded-md shadow">
                  <a
                    href="#cinemasSection"
                    class="
                      w-full
                      flex
                      items-center
                      justify-center
                      px-8
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-white
                      bg-red-900
                      hover:bg-red-600
                      md:py-4 md:text-lg md:px-10
                    "
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/3">
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="../assets/cinemaSplash.jpg"
          alt=""
        />
      </div>
    </div>
    <!-- old stuff -->
    <div class="flex mt-10 justify-center content-center">
      <h2
        id="cinemasSection"
        class="
          dark:text-white
          p-4
          rounded-xl
          dark:bg-gray-800
          bg-gray-200
          text-justify
          max-w-3xl
        "
        v-if="user"
      >
        Hi, <span class="text-red-700">{{ user.name }} </span> in this section
        you can get a overview of all the cinemas in your area and how they
        compare. You can filter based on the three categories and the overall
        score. Select a cinema if you want to read more about it or make a
        review on it.
      </h2>
    </div>
    <div class="sm:flex mt-10">
      <form class="m-10 cursor-pointer">
        <label class="dark:text-white">City:</label>
        <select name="cities" id="cities-selector">
          <option value="Copenhagen">Copenhagen</option>
          <option disabled value="Odense">Odense</option>
          <option disabled value="Aarhus">Aarhus</option>
          <option disabled value="Aalborg">Aalborg</option>
          <option disabled value="Esbjerg">Esbjerg</option>
        </select>
        <br /><br />
      </form>

      <div class="flex">
        <p class="dark:text-white transform translate-y-12">Order by:</p>
        <div class="grid grid-cols-3 gap-3 ml-2">
          <div @click="sortBy('comfort')" class="sorting cursor-pointer">
            <div class="sorting-image">
              <svg
                class="w-10 fill-current dark:text-white text-gray-900"
                width="61"
                height="65"
                viewBox="0 0 61 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.447 60.043C30.447 57.2635 30.447 54.9471 30.447 51.8896C27.0781 51.8896 23.7092 51.7969 20.3403 51.8896C16.5367 52.0749 14.2546 50.7777 12.9505 47.6275C9.03821 38.0842 4.25655 28.7262 0.996322 18.9976C-0.63379 14.087 0.126929 8.62046 0.561626 3.43187C0.6703 2.13472 3.49583 0.0963505 5.23461 0.00369716C6.9734 -0.0889562 9.14688 1.5788 10.5596 2.87595C11.3204 3.61718 11.103 5.19228 11.2117 6.39678C11.3204 8.99107 10.6683 11.7707 11.429 14.2723C12.5158 18.2564 13.6025 22.6111 16.428 25.7613C17.7321 27.2438 22.8398 26.4099 26.2087 26.5025C31.0991 26.5952 35.9894 26.5025 40.8797 26.5025C43.4879 26.5025 46.3134 26.3172 46.4221 29.4675C46.5308 32.8956 43.3792 32.803 40.6624 32.803C33.5986 32.803 26.6434 32.803 19.3622 32.803C19.5796 37.5283 21.3184 39.3814 26.9694 38.9181C36.3154 38.1769 45.7701 38.0842 55.116 37.9916C60.7671 37.8989 61.8538 39.2887 60.4411 43.9214C57.6155 53.1867 58.8109 51.7969 48.2696 51.8896C45.2267 51.8896 42.1838 51.8896 38.4889 51.8896C38.3802 54.5765 38.2715 56.9855 38.0542 60.043C42.7272 60.043 47.0741 59.8577 51.3124 60.1357C52.8339 60.2284 54.3553 61.6182 55.8767 62.3594C54.464 63.1933 53.0512 64.7684 51.6385 64.861C40.119 65.0463 28.4909 65.0463 16.8627 64.861C15.5587 64.861 14.3632 63.3786 13.0591 62.5447C14.3632 61.7108 15.5587 60.321 16.8627 60.2284C21.101 59.8577 25.448 60.043 30.447 60.043Z"
                ></path>
              </svg>
            </div>
            <p class="dark:text-white">Comfort</p>
          </div>
          <div
            @click="sortBy('snack')"
            class="transform -translate-y-3 cursor-pointer"
          >
            <div class="sorting-image">
              <svg
                class="w-10 fill-current dark:text-white text-gray-900"
                width="73"
                height="77"
                viewBox="0 0 73 77"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.8296 27.0827C38.04 27.1675 37.3952 29.8843 34.3558 35.7422C30.6717 43.0434 27.0796 50.3446 23.4876 57.6458C23.0271 58.4948 21.7376 59.6833 23.4876 60.4474C25.1454 61.2115 25.5139 59.6833 26.0665 58.7495C30.2112 52.5519 34.8163 46.4393 38.4084 39.9022C40.4347 36.2516 43.8425 34.8083 46.974 35.5724C50.2898 36.3365 53.6055 36.4214 56.8291 37.1855C61.0659 38.2042 61.4343 39.9022 58.7633 43.6377C53.9739 50.1748 49.0924 56.7119 44.2109 63.1641C43.6583 63.8433 43.1057 64.5225 42.6452 65.2017C36.3821 73.6065 35.5532 73.7763 25.6981 68.8523C20.7245 66.3902 15.6588 64.0131 10.7773 61.4662C6.07996 59.089 5.15892 56.627 7.0931 51.9576C10.8694 43.0434 14.7377 34.1292 18.6061 25.2998C19.5271 23.1774 20.2639 20.2059 22.8428 20.2908C25.1454 20.3757 27.3559 22.3284 28.3691 24.7904C28.6454 25.5545 29.3822 26.2337 29.198 27.0827C27.448 26.4884 25.8823 25.13 24.6849 28.1863C21.0929 36.6761 17.4087 44.996 13.8167 53.4009C13.3562 54.5895 12.5272 55.6082 14.5535 56.3723C16.8561 57.3062 16.8561 55.4385 17.3166 54.5895C21.3692 46.6091 25.4218 38.5438 29.3822 30.4786C29.9348 29.29 30.948 27.9316 28.8296 27.0827ZM33.1585 65.3715C34.1716 65.4564 34.54 64.6923 35.0005 64.1829C40.8031 56.4572 46.5135 48.6466 52.3161 40.921C53.2371 39.7324 53.0529 38.8834 51.5792 38.374C50.3819 38.0344 49.1845 36.1667 47.803 38.2891C42.3689 46.4393 36.9347 54.5895 31.5006 62.7396C30.3954 64.4376 31.9611 64.8621 33.1585 65.3715Z"
                ></path>
                <path
                  d="M38.2204 28.5258C39.5099 26.658 38.7731 25.045 37.2073 23.2621C36.1942 22.0736 36.5626 20.2907 37.852 18.9324C39.2336 17.4891 41.1678 17.0646 42.8256 17.9985C44.4835 18.9324 45.9572 19.0173 47.7992 18.8475C51.115 18.5928 53.8781 22.1585 52.2202 24.6205C50.6545 26.9976 48.9045 29.2899 47.615 31.8368C46.694 33.6197 44.2993 34.2139 41.9967 33.5348C39.5099 32.8556 38.4047 31.1576 38.2204 28.5258Z"
                ></path>
                <path
                  d="M35.182 25.2994C33.5241 25.7239 32.0504 25.7239 31.3136 24.79C29.3794 22.2431 27.261 20.0358 24.59 18.0831C23.669 17.4039 23.8532 16.0456 24.4058 14.9419C25.5111 12.8195 34.2609 9.84805 36.2872 11.1215C38.2214 12.3101 39.8793 13.9231 37.6688 16.2154C33.8925 20.2905 33.8004 20.2905 35.182 25.2994Z"
                ></path>
                <path
                  d="M64.4737 31.7515C64.5658 34.638 61.7106 36.9302 59.2238 35.6568C56.9212 34.4682 54.987 34.5531 52.5923 34.7229C50.6581 34.8927 49.3687 34.3833 50.3818 31.6666C51.4871 28.6103 53.9739 26.5727 55.5397 23.856C56.9212 21.4789 61.8027 22.2429 62.5395 24.9597C63.0922 27.4217 63.1843 29.8837 64.4737 31.7515Z"
                ></path>
                <path
                  d="M45.3153 16.7249C44.9468 15.0269 46.1442 14.178 47.1573 14.0082C49.552 13.6686 51.5783 12.6498 53.6967 11.8008C56.7361 10.6972 59.8677 13.0743 59.1308 15.9608C58.6703 17.7437 59.4992 20.7151 55.8151 20.8C55.1704 20.8 54.6177 21.1396 54.3414 20.4604C52.5915 16.8947 48.9994 16.64 45.3153 16.7249Z"
                ></path>
                <path
                  d="M48.6315 10.5265C48.8157 11.2906 48.0789 11.4604 47.3421 11.8C46.421 12.2245 45.5 12.649 44.579 13.0735C43.3816 13.5829 43.2895 16.1298 41.8158 15.1959C40.2501 14.1771 40.7106 11.5453 39.0527 10.187C40.6185 9.08328 41.8158 7.47023 44.1184 7.47023C46.3289 7.38533 47.8947 8.4041 48.6315 10.5265Z"
                ></path>
              </svg>
            </div>
            <p class="dark:text-white">Snacks</p>
          </div>
          <div @click="sortBy('price')" class="sorting cursor-pointer">
            <div class="sorting-image">
              <svg
                class="w-5 fill-current dark:text-white text-gray-900"
                width="42"
                height="64"
                viewBox="0 0 42 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.9036 63.1134C21.9364 63.1134 18.8793 63.1134 15.9122 63.1134C15.8223 61.5334 15.7324 59.9534 15.6424 58.4611C2.78478 53.721 -0.901682 49.9465 0.177283 42.2219C3.14444 42.2219 6.11159 42.2219 8.35943 42.2219C10.6073 44.8553 12.4955 47.1376 14.4736 49.4198C14.8332 49.1565 15.1929 48.9809 15.5525 48.7176C15.5525 44.592 15.5525 40.3786 15.5525 36.0774C7.90986 34.1462 0.447024 31.9517 0.447024 22.2082C0.447024 12.3769 6.8309 8.16349 15.6424 6.4079C15.7324 4.03786 15.8223 2.01893 15.9122 0C18.8793 0 21.9364 0 24.9036 0C24.9036 2.19449 24.9036 4.38897 24.9036 6.32012C36.6823 11.3236 40.1889 15.3614 38.7503 22.1204C35.7831 22.1204 32.816 22.1204 30.4782 22.1204C28.8598 19.9259 27.5111 18.0826 26.1624 16.327C25.8027 16.5025 25.3531 16.7659 24.9935 16.9414C24.9935 18.697 25.1733 20.3648 24.9935 22.1204C24.3641 26.5094 25.7128 28.7917 30.658 29.845C37.9411 31.5128 40.9082 35.5507 41.1779 41.6952C41.4477 48.4543 38.1209 53.6333 31.8269 56.1789C29.669 57.0567 27.4211 57.6711 25.2632 58.4611C25.0834 60.2167 24.9935 61.6212 24.9036 63.1134ZM25.1733 49.2443C29.1295 48.4543 31.1975 46.6109 31.0177 43.2753C30.8379 40.0274 28.4102 38.7985 25.1733 38.7107C25.1733 42.2219 25.1733 45.4698 25.1733 49.2443ZM15.5525 25.456C15.5525 22.2082 15.5525 19.0481 15.5525 15.537C12.2257 16.2392 10.1577 17.6437 10.2476 20.9793C10.3375 24.2271 12.3156 25.456 15.5525 25.456Z"
                ></path>
              </svg>
            </div>
            <p class="dark:text-white">Price</p>
          </div>
        </div>
      </div>
    </div>

    <ul
      role="list"
      class="
        grid grid-cols-2
        p-5
        gap-x-4
        mb-10
        gap-y-8
        sm:grid-cols-3 sm:gap-x-6
        lg:grid-cols-4
        xl:gap-x-8
      "
    >
      <li
        v-for="cinema in cinemas"
        :key="cinema.id"
        class="relative cursor-pointer"
      >
        <div class="absolute flex">
          <div class="bg-gray-900 top-0 absolute h-10 w-full opacity-70"></div>
          <div class="pl-2 z-10">
            <div class="text-white top-2 left-5 absolute">
              {{ cinema.avg_comfort.toFixed() }}
            </div>
            <svg
              class="w-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 326.38 329.15"
            >
              <title>circle_comfort</title>
              <g
                :class="
                  cinema.avg_comfort.toFixed() > 3
                    ? 'text-green-600'
                    : cinema.avg_comfort.toFixed() == 3
                    ? 'text-yellow-600'
                    : 'text-red-600'
                "
                class="fill-current"
              >
                <path
                  d="M220.46,77.92c2.89,14.34,11.1,14.8,20.85,13.14,22.74-3.87,45.49-7.75,68.3-11.14,15.8-2.35,18.34.5,16,16-2.66,18-2.78,18.13-20.76,20.48l-30.36,3.94c.4,6.62.76,12.66,1.15,19.2l42.54-1.9q.63,2.63,1.28,5.26c-4.29,2.18-8.39,5.67-12.9,6.34-25.93,3.82-51.94,7.13-78,10.17-4.12.48-8.51-1.32-15.16-2.48,12.64-14.48,28.36-9.18,42.3-14.44-.45-5.5-.93-11.49-1.58-19.52-8.87,1.16-16.87,2-24.78,3.28-9.64,1.59-16.65-1.42-21.58-10.1-11.2-19.7-24.1-38.57-33.58-59-6-13-7.6-28.23-10.32-42.62C162.35,6.23,167.2.39,175.39,0c7.89-.35,13.64,3.42,15.66,12.54a289.93,289.93,0,0,0,10.78,36.7c3.83,10.5,11.62,14.25,23.32,12,15.71-3.08,31.73-4.61,47.64-6.66,6.07-.78,13.29-.88,13,7.12-.11,3.17-6.62,8.07-10.81,8.81C257.37,73.61,239.51,75.45,220.46,77.92Z"
                  style="fill: #fff"
                ></path>
                <path
                  id="line2"
                  d="M156.64,329.15a145.39,145.39,0,0,1-55-10.66,10,10,0,0,1-5.27-13.73h0a10,10,0,0,1,12.67-4.86,127,127,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                ></path>
                <path
                  id="line3"
                  d="M75.32,305.94A146.18,146.18,0,0,1,10,224.49a10,10,0,0,1,7.16-12.81h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,305.94Z"
                ></path>
                <path
                  id="line5"
                  d="M44.8,74.2h0a10,10,0,0,1-1.37-14.53,145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,73,10,10,0,0,1,44.8,74.2Z"
                ></path>
                <path
                  id="line4"
                  d="M14.2,198.68A10,10,0,0,1,2,190.63a147.47,147.47,0,0,1-2-24.12A145.05,145.05,0,0,1,24.35,85.56,10,10,0,0,1,38.8,83.2h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,166.51a127.37,127.37,0,0,0,1.73,20.92,10,10,0,0,1-7.53,11.25Z"
                ></path>
                <g id="line1">
                  <path
                    d="M217.58,303.68h0a10,10,0,0,1,4.53-12.8,126.56,126.56,0,0,0,68.17-112.36c0-1.08,0-2.14-.06-3.21V175l20-.55v.34c0,1.26.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,303.68Z"
                  ></path>
                  <path
                    d="M299,164.42h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.77a132.25,132.25,0,0,1-28.3,22.85c-.91.55-1.83,1.08-2.76,1.6l-.3.17L234,305.45l.3-.17c1.08-.62,2.16-1.23,3.24-1.88A153.16,153.16,0,0,0,270.32,277c27-28.79,41.11-66.16,39.6-103.88A9.23,9.23,0,0,0,299,164.42Z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="pl-2 z-10 relative">
            <div class="text-white top-2 left-5 absolute">
              {{ cinema.avg_snack.toFixed() }}
            </div>
            <svg
              class="w-9"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 313.81 334.47"
            >
              <title>circle_snacks</title>
              <g
                :class="
                  cinema.avg_snack.toFixed() > 3
                    ? 'text-green-600'
                    : cinema.avg_snack.toFixed() == 3
                    ? 'text-yellow-600'
                    : 'text-red-600'
                "
                class="fill-current"
              >
                <g>
                  <path
                    d="M256,57.23c9.85,20.05,19.93,40.11,30,60,1.14,2.11,1.14,6.75,6.87,4.43,5-1.9,3-4.43,1.83-7.39-8.93-20.9-18.09-41.59-27-62.7-3-7.6-6.88-4.22-11.23-2.75-.46-2.11,1.38-3.8,2.06-5.7,2.52-6.12,8-11,13.75-11.19,6.41-.21,8.24,7.18,10.53,12.46,9.62,22,19.24,44.12,28.63,66.29,4.81,11.61,2.52,17.74-9.16,23.65-12.14,6.33-24.74,12.24-37.1,18.36-24.51,12.25-26.57,11.83-42.15-9.07-1.14-1.69-2.52-3.38-3.89-5.07C207,122.46,194.87,106.21,183,90c-6.64-9.29-5.73-13.51,4.81-16,8-1.9,16.26-2.11,24.51-4,7.79-1.9,16.26,1.68,21.3,10.76,8.93,16.26,20.38,31.46,30.69,46.87,1.37,2.32,2.29,6.12,6.41,4.22,4.35-1.9,1.15-4.85,0-7-8.93-18.16-17.86-36.32-27-54.47C236.1,55.75,234.49,49,257.4,48.78,252.13,50.89,254.65,54.27,256,57.23Zm-5.26,80.22q-20.28-30.39-40.54-60.8c-3.44-5.28-6.42-.63-9.39.21-3.67,1.27-4.13,3.38-1.84,6.34,14.43,19.21,28.63,38.63,43.06,57.84,1.15,1.27,2.06,3.17,4.58,3C249.61,142.73,253.5,141.68,250.76,137.45Z"
                    style="fill: #fff"
                  ></path>
                  <path
                    d="M224.66,64.83c-5.73,1.69-11.69.21-14-4.22-3.2-6.34-7.55-12-11.45-18-4.12-6.12,2.75-15,11-14.36,4.58.43,8.24.22,12.36-2.11s8.94-1.26,12.37,2.33c3.21,3.37,4.12,7.81,1.61,10.76-3.9,4.44-5.73,8.45-2.52,13.09C233.59,58.92,230.84,63.14,224.66,64.83Z"
                    style="fill: #fff"
                  ></path>
                  <path
                    d="M235.42,21.76c-5.5-5.7-1.38-9.71,3.43-12.67,5-3.16,26.8,4.22,29.55,9.5,1.37,2.75,1.83,6.12-.46,7.81a80.12,80.12,0,0,0-16.72,16.68c-1.83,2.32-5.5,2.32-9.62,1.27C245,31.89,244.81,31.89,235.42,21.76Z"
                    style="fill: #fff"
                  ></path>
                  <path
                    d="M173.57,43.5c1.83-6.75,14-8.65,17.41-2.74,3.89,6.76,10.07,11.82,12.82,19.42,2.52,6.76-.68,8-5.49,7.6-6-.42-10.77-.63-16.49,2.33-6.19,3.16-13.29-2.54-13.06-9.72C172,55.75,172.2,49.63,173.57,43.5Z"
                    style="fill: #fff"
                  ></path>
                  <path
                    d="M194,32.32c-.69,1.68-2.06.84-3.67.84-9.16-.21-7.1-7.6-8.24-12C180.21,14,188,8,195.56,10.78c5.27,2.11,10.31,4.65,16.26,5.49,2.52.42,5.5,2.53,4.58,6.76C207.24,22.82,198.31,23.45,194,32.32Z"
                    style="fill: #fff"
                  ></path>
                  <path
                    d="M219.38,0c5.73,0,8.7,4,12.6,6.76-4.13,3.38-3,9.92-6.87,12.45-3.67,2.33-3.9-4-6.88-5.27l-6.87-3.17c-1.83-.85-3.66-1.27-3.2-3.17C210,2.33,213.88-.2,219.38,0Z"
                    style="fill: #fff"
                  ></path>
                </g>
                <path
                  id="line2"
                  d="M156.64,334.47a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.12,127.12,0,0,0,89.12,2.28,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.43,145.73,145.73,0,0,1-48,8"
                ></path>
                <path
                  id="line3"
                  d="M75.32,311.26A146.15,146.15,0,0,1,10,229.81,10,10,0,0,1,17.2,217h0a10,10,0,0,1,11.86,6.62,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,311.26Z"
                ></path>
                <path
                  id="line5"
                  d="M44.8,79.52h0A10,10,0,0,1,43.42,65a145.43,145.43,0,0,1,98.42-48.4,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.62,125.62,0,0,0,58.31,78.33,10,10,0,0,1,44.8,79.52Z"
                ></path>
                <path
                  id="line4"
                  d="M14.2,204A10,10,0,0,1,2,196a147.38,147.38,0,0,1-2-24.12,145.05,145.05,0,0,1,24.35-81A10,10,0,0,1,38.8,88.52h0a10,10,0,0,1,2.26,13.36,125.25,125.25,0,0,0-21.06,70,127.28,127.28,0,0,0,1.73,20.92A10,10,0,0,1,14.2,204Z"
                ></path>
                <g id="line1">
                  <path
                    d="M217.58,309h0a10,10,0,0,1,4.53-12.81,126.54,126.54,0,0,0,68.17-112.36c0-1.07,0-2.14-.06-3.21v-.35l20-.55v.35c0,1.25.07,2.5.07,3.76a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,309Z"
                  ></path>
                  <path
                    d="M299,169.74h0a10.17,10.17,0,0,0-8.7,10.22c1.33,32.6-10.88,64.9-34.19,89.78a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.59l-.3.17L234,310.77l.3-.17c1.08-.61,2.16-1.23,3.24-1.88a153.16,153.16,0,0,0,32.75-26.45c27-28.79,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,169.74Z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="pl-2 z-10 relative">
            <div class="text-white top-2 left-5 absolute">
              {{ cinema.avg_price.toFixed() }}
            </div>
            <svg
              class="w-9"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 314.65 352.03"
            >
              <title>circle_price</title>
              <g
                :class="
                  cinema.avg_price.toFixed() > 3
                    ? 'text-green-600'
                    : cinema.avg_price.toFixed() == 3
                    ? 'text-yellow-600'
                    : 'text-red-600'
                "
                class="fill-current"
              >
                <path
                  d="M290.74,177.61,265.43,189.1c-2.27-4.34-4.54-8.67-6.7-12.75-42.23,3.08-57.43-2.83-64.26-25.95l23-10.45c9.69,4.54,17.91,8.55,26.4,12.45.67-1.2,1.46-2.16,2.14-3.36l-16.15-35.57c-24,4.33-47.78,7.68-60.22-19.74-12.56-27.66,0-47.67,22.58-63.87-2.78-6.78-5.1-12.58-7.43-18.38L210.13,0l8.07,17.79c39.54-1,54.56,5.92,59.15,26.77L254.07,55.13c-7.36-4.11-13.51-7.57-19.55-10.79-.79.95-1.71,2.27-2.5,3.22,2.24,4.94,4.88,9.41,6.61,14.58,3.84,13.15,10.55,17.85,25.81,14.5,22.63-4.61,36.13,3,44.74,19.91,9.39,18.68,6.64,37.5-7.82,52.7-4.95,5.23-10.49,9.83-15.55,14.81C287.54,169.23,289.08,173.3,290.74,177.61Zm-17-39.37c10.13-7.28,13.59-15.11,8.83-24.27-4.66-8.91-13.06-9.26-22.28-5.38ZM216.32,83.58,203.66,55.67c-8.47,6.22-12.5,12.82-8,22.09C200.08,86.78,207.22,87.72,216.32,83.58Z"
                  style="fill: #fff"
                ></path>
                <path
                  id="line2"
                  d="M156.64,352a145.2,145.2,0,0,1-55-10.66,10,10,0,0,1-5.27-13.72h0a10,10,0,0,1,12.67-4.86,127.19,127.19,0,0,0,89.12,2.27,10,10,0,0,1,12.4,5.5h0a10,10,0,0,1-6,13.44,146,146,0,0,1-48,8"
                ></path>
                <path
                  id="line3"
                  d="M75.32,328.83A146.18,146.18,0,0,1,10,247.38a10,10,0,0,1,7.16-12.82h0a10,10,0,0,1,11.86,6.63,126.2,126.2,0,0,0,56.47,70.44,10,10,0,0,1,3.87,13h0A10,10,0,0,1,75.32,328.83Z"
                ></path>
                <path
                  id="line5"
                  d="M44.8,97.08h0a10,10,0,0,1-1.37-14.54,145.42,145.42,0,0,1,98.42-48.39,10,10,0,0,1,10.8,10h0a10,10,0,0,1-9.21,10A125.67,125.67,0,0,0,58.31,95.9,10,10,0,0,1,44.8,97.08Z"
                ></path>
                <path
                  id="line4"
                  d="M14.2,221.56a10,10,0,0,1-12.21-8A147.56,147.56,0,0,1,0,189.4a145.05,145.05,0,0,1,24.35-80.95,10,10,0,0,1,14.45-2.36h0a10,10,0,0,1,2.26,13.36A125.25,125.25,0,0,0,20,189.4a127.25,127.25,0,0,0,1.73,20.91,10,10,0,0,1-7.53,11.25Z"
                ></path>
                <g id="line1">
                  <path
                    d="M217.58,326.57h0a10,10,0,0,1,4.53-12.8A126.56,126.56,0,0,0,290.28,201.4c0-1.08,0-2.14-.06-3.21v-.35l20-.56v.35c0,1.25.07,2.51.07,3.77a146.52,146.52,0,0,1-78.86,130.06A10,10,0,0,1,217.58,326.57Z"
                  ></path>
                  <path
                    d="M299,187.3h0a10.18,10.18,0,0,0-8.7,10.23c1.33,32.59-10.88,64.89-34.19,89.77a132.56,132.56,0,0,1-28.3,22.85c-.91.55-1.83,1.07-2.76,1.6l-.3.17L234,328.33l.3-.17c1.08-.61,2.16-1.22,3.24-1.87a153.16,153.16,0,0,0,32.75-26.45c27-28.8,41.11-66.16,39.6-103.88A9.24,9.24,0,0,0,299,187.3Z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div
          class="
            w-full
            aspect-w-10 aspect-h-7
            rounded-lg
            bg-gray-100
            dark:bg-gray-900
            focus-within:ring-2
            focus-within:ring-offset-2
            focus-within:ring-offset-gray-100
            focus-within:ring-red-700
            overflow-hidden
          "
        >
          <router-link :to="'/cinema/' + cinema.id">
            <img
              :src="require(`../assets/cinemas/${cinema.Cinema_picture}.jpg`)"
              alt=""
              class="max-h-40 w-full pointer-events-none group-hover:opacity-75"
            />
          </router-link>
          <!-- <button type="button" class="absolute inset-0 focus:outline-none">
          <span class="sr-only">View details for </span>
        </button> -->
        </div>
        <div>
          <p
            class="
              mt-2
              block
              text-sm
              font-medium
              dark:text-white
              text-gray-900
              truncate
              pointer-events-none
            "
          >
            {{ cinema.Cinema_name }}
          </p>
        </div>
      </li>
      <!-- ends here -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Frontpage",
  data() {
    return {
      sort: "",
    };
  },
  computed: {
    ...mapGetters(["user", "cinemas"]),
  },
  async created() {
    if (!localStorage.getItem("token")) {
      return this.$router.push("/login");
    }
    const user = await axios.get("user", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    const cinemas = await axios.get("AllCinemaRatings?sort=" + this.sort, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log(cinemas.data[1]);
    this.$store.dispatch("cinemas", cinemas.data);
    this.$store.dispatch("user", user.data);
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      localStorage.removeItem("token");
      this.$store.dispatch("user", null);
      this.$router.push("/login");
    },
    async sortBy(variable) {
      this.sort = variable;
      const cinemas = await axios.get("AllCinemaRatings?sort=" + this.sort, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.$store.dispatch("cinemas", cinemas.data);
    },
    switchMode() {
      let div = document.querySelector("#main-container");
      if (div.className === "dark") {
        div.classList.remove("dark");
      } else {
        div.classList.add("dark");
      }
    },
  },
};
</script>
