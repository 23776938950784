import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default createStore({
  state: {
    open: false,
    user: null,
    signupName: "",
    signupPassword: "",
    signupEmail: "",
    loginEmail: "",
    loginPassword: "",
    loginError: "",
    signupError: "",
    cinemas: "",
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    cinemas: (state) => {
      return state.cinemas;
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    cinemas(state, cinemas) {
      state.cinemas = cinemas;
    },
    loginError(state, payload) {
      state.loginError = payload;
    },
    signupError(state, payload) {
      state.signupError = payload;
    },
    OpenSignup(state) {
      state.open = true;
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    cinemas(context, cinemas) {
      context.commit("cinemas", cinemas);
    },
    async signup(context) {
      //validation
      if (!this.state.signupName.length) {
        return context.commit("signupError", "Name required");
      }
      if (!this.state.signupPassword.length) {
        return context.commit("signupError", "Password required");
      }
      if (this.state.signupPassword.length < 6) {
        return context.commit(
          "signupError",
          "Password must be at least 6 characters"
        );
      }

      try {
        const response = await axios.post("register", {
          name: this.state.signupName,
          email: this.state.signupEmail,
          password: this.state.signupPassword,
          password_confirmation: this.state.signupPassword,
        });
        console.log(response.data.token);
        this.state.open = false;
      } catch (e) {
        context.commit("signupError", "Email is already used on other account");
      }
    },
    async login(context) {
      try {
        console.log("login from store");
        const response = await axios.post("login", {
          email: this.state.loginEmail,
          password: this.state.loginPassword,
        });
        console.log(response.data.token);
        localStorage.setItem("token", response.data.token);
        this.dispatch("user", response.data.user);
        // context.commit("user", response.data.user);
        context.commit("Error", "");
        router.push("/");
      } catch (e) {
        context.commit("loginError", "Invalid Email/Password");
      }
    },
    async logout() {
      console.log("logout from store");
      const response = await axios.post("logout");
      console.log(response);
    },
  },
  modules: {},
});
