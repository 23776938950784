<template>
  <div
    class="
      min-h-screen
      dark:bg-gray-900
      bg-gray-50
      flex flex-col
      justify-center
      py-12
      sm:px-6
      lg:px-8
    "
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div>
        <svg
          class="mx-auto h-20 w-auto"
          id="price"
          xmlns="http://www.w3.org/2000/svg"
          width="116.26mm"
          height="115.5mm"
          viewBox="0 0 329.54 327.39"
        >
          <g>
            <path
              d="M164.77,327.39a157.28,157.28,0,0,1-78.21-20.65,15.09,15.09,0,0,1-4.81-21.91h0a15.12,15.12,0,0,1,19.78-4.27,128.22,128.22,0,0,0,126.48,0,15.12,15.12,0,0,1,19.78,4.27h0A15.09,15.09,0,0,1,243,306.74,157.25,157.25,0,0,1,164.77,327.39Z"
              class="fill-current text-red-800"
            />
            <path
              d="M255.65,279.72h0a15.05,15.05,0,0,1,1.93-20.05A128.36,128.36,0,0,0,296.8,139.28,15.14,15.14,0,0,1,307,121.79h0a15.06,15.06,0,0,1,19.32,11.26A158.53,158.53,0,0,1,278,281.86,15.09,15.09,0,0,1,255.65,279.72Z"
              class="fill-current text-red-800"
            />
            <path
              d="M25.9,112.58h0a15.1,15.1,0,0,1-9-20.6A158.36,158.36,0,0,1,143.43.1,15.1,15.1,0,0,1,160.25,15h0A15.09,15.09,0,0,1,146.8,30.07,128.24,128.24,0,0,0,44.4,104.45,15.09,15.09,0,0,1,25.9,112.58Z"
              class="fill-current text-red-800"
            />
            <path
              d="M73.9,279a15.11,15.11,0,0,1-22.37,2.13,158.51,158.51,0,0,1-48.3-148.8,15.05,15.05,0,0,1,19.32-11.26h0A15.12,15.12,0,0,1,32.74,138.6,128.34,128.34,0,0,0,72,259,15.08,15.08,0,0,1,73.9,279Z"
              class="fill-current text-red-800"
            />
            <path
              d="M285.14,104.45a128.22,128.22,0,0,0-102.4-74.38A15.1,15.1,0,0,1,169.29,15h0A15.1,15.1,0,0,1,186.11.1,158.35,158.35,0,0,1,312.6,92a15.1,15.1,0,0,1-9,20.6h0A15.1,15.1,0,0,1,285.14,104.45Z"
              class="fill-current text-red-800"
            />
            <g>
              <path
                d="M233.3,192.6l-15.14-9.66c-2.38,6.09-1,12.06-1.21,17.82-.46,10.87-5.39,16.08-16.37,16.12-31.53.1-63.06.05-94.59,0-7.25,0-13.07-2.17-14.7-10.27.24-31,.36-62.06-.07-93.09.89-7.57,6.3-10.2,12.72-10.25q49.45-.35,98.92,0c8.86.08,13.45,5.44,14,14.61.18,3,.05,6,.05,9.08s0,5.89,0,9.3c6.52-1.14,9.91-6,14.8-8.06a2,2,0,0,1,2.84.59,10.64,10.64,0,0,1,2.64,5.68c1.25,17.13.94,34.28.2,51.43A7.94,7.94,0,0,1,233.3,192.6Z"
                class="fill-current text-red-800"
              />
              <path
                d="M91.22,113.55c2.42,1.66,2,4.2,2,6.58q0,39.94,0,79.9c0,2.38.41,4.92-2,6.61-2.09-3.35-1.21-7.08-1.22-10.65q-.12-36.35,0-72.7C90.08,120,89.21,116.6,91.22,113.55Z"
                class="fill-current text-red-800"
              />
              <path
                d="M244.25,195.85l-.82,0-.79-.24c-2.21-2.41-2.49-5.39-2.51-8.44q-.13-27.09,0-54.18c0-3,.22-6,2.37-8.49,4.52-3.69,6.07-.86,6.09,3.19q.22,32.25,0,64.49c0,2.34.32,6.22-4.3,3.7,2.33-7.66.9-15.51,1-23.25.25-14.51.07-29,.07-43.53C244.57,151.36,246.82,173.64,244.25,195.85Z"
                class="fill-current text-red-800"
              />
              <path
                d="M233.51,128.53l-1.81-.31c1.21-1.23,2.42-2.47,3.64-3.7,3.93-1.68,5.17,1.07,5.18,3.86.12,21.91,1.25,43.84-.57,65.73,0,.53-.64,1-1,1.5-1.64.35-3,.06-3.94-1.51,2.67-19.68.64-39.47,1.26-59.2A8.24,8.24,0,0,0,233.51,128.53Z"
                class="fill-current text-red-800"
              />
              <path
                d="M233.51,128.53c1.69-.83,3.65-1.79,3.64,1.34q-.12,31.09-.49,62.2c0,.68-1.06,1.36-1.63,2l-1.73-1.51C236.81,171.25,236.32,149.89,233.51,128.53Z"
                class="fill-current text-red-800"
              />
              <path
                d="M239,195.61c0-21.13.09-42.25,0-63.38,0-2.9,1.73-7.09-3.6-7.71,1.85-2.41,3.69-2.26,5.53,0a592,592,0,0,1,0,71.11Z"
                class="fill-current text-red-800"
              />
              <path
                d="M240.83,195.63q0-35.55,0-71.1h1.64q.06,35.55.13,71.11h-.91Z"
                class="fill-current text-red-800"
              />
              <path
                d="M134.57,161.28c0-6.07.07-12.14,0-18.21-.07-4,1-5.95,5.25-3.67q18,9.65,36.16,19.19c4.27,2.24,2.57,4-.35,5.56-12,6.46-24.11,12.8-36.12,19.29-3.67,2-4.94.73-4.91-3.05C134.61,174,134.58,167.65,134.57,161.28Z"
                class="fill-current text-white"
              />
              <path
                d="M100.87,212.45H206.06v.9h-105Z"
                class="fill-current text-red-800"
              />
              <path
                d="M104.81,104.83H202v.89H104.44Z"
                class="fill-current text-red-800"
              />
              <path
                d="M244.25,195.85V126.22H246q0,33.81-.08,67.63c0,.68-1,1.37-1.61,2.06Z"
                class="fill-current text-red-800"
              />
            </g>
          </g>
        </svg>
      </div>
      <h2
        class="
          mt-6
          text-center text-3xl
          font-extrabold
          dark:text-white
          text-gray-900
        "
      >
        Sign in to your account
      </h2>
      <p class="mt-2 text-center text-sm dark:text-gray-300 text-gray-600">
        Or
        {{ " " }}
        <span
          @click="openSignup"
          class="font-medium cursor-pointer text-red-700 hover:text-red-500"
        >
          Create a new account
        </span>
      </p>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div
        class="
          bg-white
          dark:bg-gray-800
          py-8
          px-4
          shadow
          sm:rounded-lg sm:px-10
        "
      >
        <form class="space-y-6" @submit.prevent="login">
          <div
            v-if="$store.state.loginError"
            class="rounded-md dark:bg-red-600 bg-red-50 p-4"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon
                  class="h-5 w-5 dark:text-white text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium dark:text-white text-red-800">
                  {{ $store.state.loginError }}
                </h3>
              </div>
            </div>
          </div>
          <div>
            <label
              for="email"
              class="block text-sm font-medium dark:text-gray-200 text-gray-700"
            >
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="$store.state.loginEmail"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  dark:border-gray-600 dark:bg-gray-800 dark:text-white
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  dark:focus:ring-gray-500 dark:focus:border-gray-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium dark:text-gray-200 text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="$store.state.loginPassword"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border
                  dark:border-gray-600 dark:bg-gray-800 dark:text-white
                  border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  dark:focus:ring-gray-500 dark:focus:border-gray-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm">
              <router-link
                to="/forgotPassword"
                class="font-medium text-red-700 hover:text-red-500"
              >
                Forgot your password?
              </router-link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-white
                bg-red-700
                hover:bg-red-500
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-red-500
              "
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
    <signup />
    <div
      @click="switchMode"
      class="dark:text-white cursor-pointer absolute top-3 right-5"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Signup from "../components/Signup.vue";
// import { SunIcon } from "@vue-hero-icons/outline";
import { XCircleIcon } from "@heroicons/vue/solid";

export default {
  name: "Login",
  created() {
    if (localStorage.getItem("token")) {
      return this.$router.push("/");
    }
  },
  components: {
    Signup,
    XCircleIcon,
    // SunIcon,
  },
  methods: {
    openSignup() {
      this.$store.commit("OpenSignup");
    },
    switchMode() {
      let div = document.querySelector("#main-container");
      if (div.className === "dark") {
        div.classList.remove("dark");
      } else {
        div.classList.add("dark");
      }
    },
    login() {
      this.$store.dispatch("login");
    },
  },
};
</script>
